import { ReactElement, useCallback } from 'react'
import { ChainId, Currency } from '@pancakeswap/sdk'
import { Modal, InjectedModalProps, ModalProps } from '@pancakeswap/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import Link from 'next/link'

export function ConfirmationPendingContent({ pendingText }: { pendingText?: string }) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 h-[300px] w-[300px]">
      <div className="h-24 w-24 flex items-center justify-center">
        <span className="text-6xl font-bold">🤔</span>
      </div>
      <div className="flex flex-col items-center justify-center gap-2  text-orange-200">
        <div className="text-balance text-center">{pendingText || 'Waiting for confirmation...'}</div>
        <div className="opacity-60 text-sm"> Confirm this transaction in your wallet</div>
      </div>
    </div>
  )
}

export function TransactionSubmittedContent({
  chainId,
  hash,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 h-[300px] w-[300px]">
      <div className="h-24 w-24 flex items-center justify-center">
        <span className="text-6xl font-bold">🎉</span>
      </div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div>Transaction Submitted</div>
        {chainId && hash && (
          <Link target="_blank" href={getBlockExploreLink(hash, 'transaction', chainId)}>
            <span className="opacity-60 text-sm">{`View on ${getBlockExploreName(chainId)}`}</span>
          </Link>
        )}
      </div>
    </div>
  )
}
interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<
  React.PropsWithChildren<InjectedModalProps & ConfirmationModalProps & ModalProps>
> = ({ title, onDismiss, customOnDismiss, attemptingTxn, hash, pendingText, content, currencyToAdd, ...props }) => {
  const { chainId } = useActiveChainId()

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss?.()
  }, [customOnDismiss, onDismiss])

  if (!chainId) return null

  return (
    <Modal title={title} headerBackground="gradientCardHeader" {...props} onDismiss={handleDismiss}>
      {attemptingTxn ? (
        <ConfirmationPendingContent pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={handleDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}

export default TransactionConfirmationModal
