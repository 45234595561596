import { ArrowLeft, ArrowRight, Link2Off } from 'lucide-react'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useAccount } from 'wagmi'
import Avatar from 'boring-avatars'
import { formatAddress } from 'utils/address'
import { useRouter } from 'next/router'
import useAuth from 'hooks/useAuth'
import Link from 'next/link'

export default function Account() {
  const router = useRouter()
  const { logout } = useAuth()
  const { isConnected, address } = useAccount()

  if (!isConnected)
    return (
      <ConnectWalletButton>
        <div className="bg-black rounded-2xl p-4">
          <div className="flex flex-row items-center justify-between">
            <span className="uppercase text-orange-200 text-2xl font-bold">Connect</span>
            <ArrowRight className=" text-orange-200 h-6 w-6" />
          </div>
        </div>
      </ConnectWalletButton>
    )

  return (
    <div className="bg-black rounded-2xl px-4 py-2 h-16 flex items-center">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center gap-4">
          <Avatar name={address} colors={['#fb6900', '#f63700', '#fdba74', '#f97316', '#fef08a']} size={32} />
          <Link target="_blank" href={`https://scan.rss3.io/address/${address}`}>
            <span className="text-xl text-orange-200 font-bold">{formatAddress(address)}</span>
          </Link>
        </div>
        <div className="flex flex-row items-center gap-4">
          <button
            type="button"
            onClick={() => {
              router.back()
            }}
          >
            <ArrowLeft className=" text-orange-200 h-6 w-6" />
          </button>
          <button
            type="button"
            onClick={() => {
              logout()
            }}
          >
            <Link2Off className=" text-orange-200 h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  )
}
