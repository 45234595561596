import { useState } from 'react'
import { escapeRegExp } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { useUserSlippageTolerance, useUserTransactionTTL } from 'state/user/hooks'
import { Badge } from 'components/ui/badge'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
const THREE_DAYS_IN_SECONDS = 60 * 60 * 24 * 3

const SlippageTabs = () => {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const { t } = useTranslation()

  const slippageInputIsValid =
    slippageInput === '' || (userSlippageTolerance / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (ttl / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && userSlippageTolerance < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && userSlippageTolerance > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  const parseCustomSlippage = (value: string) => {
    if (value === '' || inputRegex.test(escapeRegExp(value))) {
      setSlippageInput(value)

      try {
        const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
        if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
          setUserSlippageTolerance(valueAsIntFromRoundedFloat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const parseCustomDeadline = (value: string) => {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 60 && valueAsInt < THREE_DAYS_IN_SECONDS) {
        setTtl(valueAsInt)
      } else {
        deadlineError = DeadlineError.InvalidInput
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="flex flex-col gap-8 pt-4 w-[400px]">
      <div className="flex flex-col items-start justify-start w-full gap-2">
        <div className="text-sm text-orange-200 opacity-60">Slippage Tolerance</div>
        <div>
          <div className="flex flex-row items-center justify-center gap-4">
            <Badge
              variant={userSlippageTolerance === 10 ? 'default' : 'outline'}
              className={userSlippageTolerance === 10 ? 'bg-orange-200' : 'border-orange-200 text-orange-200'}
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(10)
              }}
            >
              0.1%
            </Badge>
            <Badge
              variant={userSlippageTolerance === 50 ? 'default' : 'outline'}
              className={userSlippageTolerance === 50 ? 'bg-orange-200' : 'border-orange-200 text-orange-200'}
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(50)
              }}
            >
              0.5%
            </Badge>
            <Badge
              variant={userSlippageTolerance === 100 ? 'default' : 'outline'}
              className={userSlippageTolerance === 100 ? 'bg-orange-200' : 'border-orange-200 text-orange-200'}
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(100)
              }}
            >
              1.0%
            </Badge>
            <Badge
              variant={userSlippageTolerance === 500 ? 'default' : 'outline'}
              className={userSlippageTolerance === 500 ? 'bg-orange-200' : 'border-orange-200 text-orange-200'}
              onClick={() => {
                setSlippageInput('')
                setUserSlippageTolerance(500)
              }}
            >
              5.0%
            </Badge>
            <input
              className="w-16 text-xs border border-orange-200 rounded-md px-2 py-0.5 outline-none font-bold text-orange-200"
              inputMode="decimal"
              pattern="^[0-9]*[.,]?[0-9]{0,2}$"
              placeholder={(userSlippageTolerance / 100).toFixed(2)}
              value={slippageInput}
              onBlur={() => {
                parseCustomSlippage((userSlippageTolerance / 100).toFixed(2))
              }}
              onChange={(event) => {
                if (event.currentTarget.validity.valid) {
                  parseCustomSlippage(event.target.value.replace(/,/g, '.'))
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between">
        <div className="text-sm text-orange-200 opacity-60">Tx deadline (mins)</div>
        <input
          className="w-16 text-xs border border-orange-200 rounded-md px-2 py-0.5 outline-none font-bold text-orange-200"
          inputMode="numeric"
          pattern="^[0-9]+$"
          onBlur={() => {
            parseCustomDeadline((ttl / 60).toString())
          }}
          placeholder={(ttl / 60).toString()}
          value={deadlineInput}
          onChange={(event) => {
            if (event.currentTarget.validity.valid) {
              parseCustomDeadline(event.target.value)
            }
          }}
        />
      </div>
    </div>
  )
}

export default SlippageTabs
