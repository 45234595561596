import { useState } from 'react'
import { Token, Currency, ChainId } from '@pancakeswap/sdk'
import { Text, Flex, Grid, BscScanIcon, useTooltip, HelpIcon, AutoColumn } from '@pancakeswap/uikit'
import { useAddUserToken } from 'state/user/hooks'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import useSWRImmutable from 'swr/immutable'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useCombinedInactiveList } from 'state/lists/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { chains } from 'utils/wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { fetchRiskToken, TOKEN_RISK } from 'views/Swap/hooks/fetchTokenRisk'
import Link from 'next/link'
import { Button, Checkbox } from '@mantine/core'

interface ImportProps {
  tokens: Token[]
  handleCurrencySelect?: (currency: Currency) => void
}

const getStandard = (chainId: ChainId) =>
  chainId !== ChainId.BSC && chainId !== ChainId.BSC_TESTNET ? 'ERC20' : 'BEP20'

function ImportToken({ tokens, handleCurrencySelect }: ImportProps) {
  const { chainId } = useActiveChainId()

  const { t } = useTranslation()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList()

  const { data: hasRiskToken } = useSWRImmutable(tokens && ['has-risks', tokens], async () => {
    const result = await Promise.all(tokens.map((token) => fetchRiskToken(token.address, token.chainId)))
    return result.some((r) => r.riskLevel > TOKEN_RISK.MEDIUM)
  })

  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    t('I have read the scanning result, understood the risk and want to proceed with token importing.'),
  )

  return (
    <AutoColumn gap="lg">
      <div className="w-full rounded-lg border border-lime-9 bg-white p-4 text-sm text-gray-6">
        {`Anyone can create a ${getStandard(chainId)} token on ${chains.find((c) => c.id === chainId)?.name
          } with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.`}
      </div>

      {tokens.map((token) => {
        const list = token.chainId && inactiveTokenList?.[token.chainId]?.[token.address]?.list
        const address = token.address ? `${truncateHash(token.address)}` : null
        return (
          <div className="flex flex-col w-full gap-2">
            {list !== undefined ? (
              <span className="text-[#00d77c] text-sm">{`Via ${list.name}`}</span>
            ) : (
              <span className="text-[#e8463a] text-sm">Unknown Source</span>
            )}
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-2">
                <span>{token.name}</span>
                <span>({token.symbol})</span>
              </div>
              <div className="text-gray-6 text-sm">{address}</div>
            </div>
            {token.chainId && (
              <Link
                className="text-gray-6 text-sm"
                href={getBlockExploreLink(token.address, 'address', token.chainId)}
                target="_blank"
              >
                ({`View on ${getBlockExploreName(token.chainId)}`})
              </Link>
            )}
          </div>
        )
      })}

      <Grid gridTemplateRows="1fr 1fr" gridGap="4px">
        <Flex alignItems="center" onClick={() => setConfirmed(!confirmed)}>
          <Checkbox
            name="confirmed"
            type="checkbox"
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
          />
          <Text ml="8px" style={{ userSelect: 'none' }}>
            {hasRiskToken ? t('I acknowledge the risk') : t('I understand')}
          </Text>
          {hasRiskToken && (
            <div ref={targetRef}>
              <HelpIcon color="textSubtle" />
              {tooltipVisible && tooltip}
            </div>
          )}
        </Flex>
        <Button
          className="bg-lime-9 w-full"
          size='lg'
          disabled={!confirmed}
          onClick={() => {
            tokens.forEach((token) => {
              const inactiveToken = chainId && inactiveTokenList?.[token.chainId]?.[token.address]
              let tokenToAdd = token
              if (inactiveToken) {
                tokenToAdd = new WrappedTokenInfo({
                  ...token,
                  logoURI: inactiveToken.token.logoURI,
                  name: token.name || inactiveToken.token.name,
                })
              }
              addToken(tokenToAdd)
            })
            if (handleCurrencySelect) {
              handleCurrencySelect(tokens[0])
            }
          }}
        >
          {hasRiskToken ? t('Proceed') : t('Import')}
        </Button>
      </Grid>
    </AutoColumn>
  )
}

export default ImportToken
