import { ReactNode } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { ArrowUpRight } from 'lucide-react'

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="w-full h-screen grid md:grid-cols-5 xl:grid-cols-4 px-4 pt-4 md:px-8 md:pt-8">
      <div className="hidden md:col-span-3 h-full md:flex flex-col items-start justify-between">
        <div className="flex flex-row items-center gap-4">
          <Link href="/">
            <div className="flex flex-row items-center gap-4 group">
              <Image
                src="/ui/logo.svg"
                className="group-hover:rotate-[60deg] transition-all"
                alt=""
                width={56}
                height={56}
              />
              <p className="font-bold text-black">
                Powered by
                <br /> OpenSwap Team
              </p>
            </div>
          </Link>
          <div className="h-12 w-[1px] bg-black" />
          <Link href="https://explorer.rss3.io/bridge" target="_blank">
            <p className="font-bold text-black flex flex-col items-start justify-start group">
              <div className="flex items-center -gap-2">
                <span>Bridge</span>
                <ArrowUpRight className="h-5 w-5 group-hover:scale-125 transition-all" />
              </div>
              <span>{'Ethereum -> RSS3 VSL'}</span>
            </p>
          </Link>
        </div>
        <div>
          <h1 className="text-[15vh] font-bold text-orange-200 uppercase">OpenSwap</h1>
        </div>
      </div>
      <div className="md:col-span-2 xl:col-span-1 overflow-auto max-h-full scrollbar-hide">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-4 justify-start sm:hidden">
            <Link href="/">
              <div className="flex flex-row items-center gap-4">
                <Image src="/ui/logo.svg" alt="" width={36} height={36} />
                <p className="font-bold text-black uppercase text-xl">OpenSwap</p>
              </div>
            </Link>
            <div className="h-8 w-[1px] bg-black" />
            <Link href="https://explorer.rss3.io/bridge" target="_blank">
              <div className="flex flex-row items-center -gap-2">
                <p className="font-bold text-black capitalize text-xl">Bridge</p>
                <ArrowUpRight className="h-6 w-6 text-black" />
              </div>
            </Link>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
