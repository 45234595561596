import { Modal } from '@pancakeswap/uikit'

const ConfirmSwapModalContainer = ({ children, handleDismiss }) => {
  return (
    <Modal title="Review swap" minWidth="28rem" onDismiss={handleDismiss}>
      {children}
    </Modal>
  )
}

export default ConfirmSwapModalContainer
