import { ReactElement, useMemo } from 'react'
import { TradeType, CurrencyAmount, Currency, Percent } from '@pancakeswap/sdk'
import { Text, AutoColumn } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { CurrencyLogo } from 'components/Logo'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { ArrowDown, ArrowRight } from 'lucide-react'

export default function SwapModalHeader({
  inputAmount,
  outputAmount,
  tradeType,
  slippageAdjustedAmounts,
  recipient,
}: {
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  tradeType: TradeType
  priceImpactWithoutFee?: Percent
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  allowedSlippage: number | ReactElement
}) {
  const amount =
    tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol = tradeType === TradeType.EXACT_INPUT ? outputAmount.currency.symbol : inputAmount.currency.symbol

  const tradeInfoText = useMemo(() => {
    return tradeType === TradeType.EXACT_INPUT
      ? `Output is estimated. You will receive at least ${amount} ${symbol} or the transaction will revert.`
      : `Input is estimated. You will sell at most ${amount} ${symbol} or the transaction will revert.`
  }, [tradeType, amount, symbol])

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = `Output will be sent to ${truncatedRecipient}`

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <div className="flex flex-col w-full gap-2 sm:gap-4">
      <div className="text-orange-200 max-w-[400px]">{tradeInfoText}</div>
      <div className="flex flex-col gap-4 py-8">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6 text-orange-200">
          <div className="flex flex-row items-center gap-2 text-lg font-bold">
            <span>{inputAmount.toSignificant(6)}</span>
            <CurrencyLogo currency={inputAmount.currency} size="24px" />
            <span>{inputAmount.currency.symbol}</span>
          </div>
          <ArrowDown className="block sm:hidden" size={20} />
          <ArrowRight className="hidden sm:block" size={20} />
          <div className="flex flex-row items-center gap-2 text-lg font-bold">
            <span>{outputAmount.toSignificant(6)}</span>
            <CurrencyLogo currency={outputAmount.currency} size="24px" />
            <span>{outputAmount.currency.symbol}</span>
          </div>
        </div>
      </div>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </div>
  )
}
