import { useMemo, useState } from 'react'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Field } from 'state/swap/actions'
import { basisPointsToPercent, computeTradePriceBreakdown, formatExecutionPrice, warningSeverity } from 'utils/exchange'
import { ONE_BIPS } from 'config/constants/exchange'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'

export default function SwapModalFooter({
  trade,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  onConfirm: () => void
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted] = useState<boolean>(false)
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const [allowedSlippage] = useUserSlippageTolerance()

  return (
    <div className="flex flex-col gap-2">
      <Separator orientation="horizontal" />
      <div className="flex flex-col w-full gap-1 pb-2 text-orange-200">
        <div className="flex justify-between text-sm">
          <div className="opacity-60">Slippage Tolerance</div>
          <div>
            {typeof allowedSlippage === 'number'
              ? `${basisPointsToPercent(allowedSlippage).toFixed(2)}%`
              : allowedSlippage}
          </div>
        </div>

        <div className="flex justify-between text-sm">
          <div className="opacity-60">Max Slippage</div>
          <div>
            {priceImpactWithoutFee
              ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                ? '<0.01%'
                : `${priceImpactWithoutFee.toFixed(2)}%`
              : '-'}
          </div>
        </div>

        <div className="flex justify-between text-sm">
          <div className="opacity-60">Service Fee</div>
          <div>{realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}</div>
        </div>

        <div className="flex justify-between text-sm">
          <div className="opacity-60">Price</div>
          <div>{formatExecutionPrice(trade, showInverted)}</div>
        </div>
      </div>

      <Button className="mt-4 bg-orange-200 w-full" onClick={onConfirm} disabled={disabledConfirm}>
        {severity > 2 || (trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance)
          ? 'Swap Anyway'
          : 'Confirm Swap'}
      </Button>

      {swapErrorMessage ? <div className="text-xs text-left opacity-60">{swapErrorMessage}</div> : null}
    </div>
  )
}
