import { useState } from 'react'
import { Token } from '@pancakeswap/sdk'
import { TokenList } from '@pancakeswap/token-lists'
import { cn } from 'utils'
import { Badge } from 'components/ui/badge'
import ManageLists from './ManageLists'
import ManageTokens from './ManageTokens'
import { CurrencyModalView } from './types'

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
}) {
  const [showLists, setShowLists] = useState(true)

  return (
    <div className="min-h-[400px]">
      <div className="flex flex-row items-center gap-2 pb-8">
        <Badge
          onClick={() => setShowLists((prev) => !prev)}
          variant={showLists ? 'default' : 'outline'}
          className={cn(showLists ? 'bg-orange-200 capitalize' : 'border-orange-200 text-orange-200 capitalize')}
        >
          Lists
        </Badge>
        <Badge
          onClick={() => setShowLists((prev) => !prev)}
          variant={!showLists ? 'default' : 'outline'}
          className={cn(!showLists ? 'bg-orange-200 capitalize' : 'border-orange-200 text-orange-200 capitalize')}
        >
          Tokens
        </Badge>
      </div>

      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : (
        <ManageTokens setModalView={setModalView} setImportToken={setImportToken} />
      )}
    </div>
  )
}
