import { useMemo, useState } from 'react'
import { Currency, Pair, Token, Percent, CurrencyAmount } from '@pancakeswap/sdk'
import { useModal, NumericalInput } from '@pancakeswap/uikit'
import { isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'

import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { StablePair } from 'views/AddLiquidity/AddStableLiquidity/hooks/useStableLPDerivedMintInfo'

import { useAccount } from 'wagmi'
import { ChevronDown } from 'lucide-react'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import AddToWalletButton from 'components/AddToWallet/AddToWalletButton'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | StablePair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  showSearchInput?: boolean
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean
  showUSDPrice?: boolean
  tokensToShow?: Token[]
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onPercentInput,
  onMax,
  showQuickInputButton = false,
  showMaxButton,
  maxAmount,
  lpPercent,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  beforeButton,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  showSearchInput,
  disabled,
  error,
  showUSDPrice,
  tokensToShow,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token && isAddress(token.address) ? token.address : null

  const amountInDollar = useBUSDCurrencyAmount(
    showUSDPrice ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      showSearchInput={showSearchInput}
      tokensToShow={tokensToShow}
    />,
  )

  const percentAmount = useMemo(
    () => ({
      25: maxAmount ? maxAmount.multiply(new Percent(25, 100)).toExact() : undefined,
      50: maxAmount ? maxAmount.multiply(new Percent(50, 100)).toExact() : undefined,
      75: maxAmount ? maxAmount.multiply(new Percent(75, 100)).toExact() : undefined,
    }),
    [maxAmount],
  )

  const [currentClickedPercent, setCurrentClickedPercent] = useState('')

  const isAtPercentMax = (maxAmount && value === maxAmount.toExact()) || (lpPercent && lpPercent === '100')

  return (
    <div className="flex flex-row items-start justify-between w-full gap-6">
      <div className="flex flex-col gap-1 flex-1">
        <NumericalInput
          className="w-full text-4xl font-bold outline-none text-orange-200 bg-inherit truncate"
          align="left"
          error={error}
          disabled={disabled}
          value={value}
          onBlur={onInputBlur}
          onUserInput={(val) => {
            onUserInput(val)
            setCurrentClickedPercent('')
          }}
        />
        <div className="text-sm text-orange-200 opacity-60">
          {account
            ? !hideBalance && !!currency
              ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
              : ' -'
            : 'Balance: -'}
        </div>
      </div>
      <div className="flex flex-col items-end justify-between h-full min-h-16">
        <button
          className="flex flex-row items-center gap-2 rounded-full px-1 py-0.5  text-orange-200"
          type="button"
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal()
            }
          }}
        >
          {pair ? (
            <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} />
          ) : currency ? (
            currency?.symbol.toLowerCase() === 'eth' ? null : (
              <CurrencyLogo currency={currency} size="20px" />
            )
          ) : null}
          {pair ? (
            <span>
              {pair?.token0.symbol}:{pair?.token1.symbol}
            </span>
          ) : (
            <span>
              {(currency && currency.symbol && currency.symbol.length > 10
                ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                    currency.symbol.length - 5,
                    currency.symbol.length,
                  )}`
                : currency?.symbol.toLowerCase() === 'eth'
                ? '---'
                : currency?.symbol) || t('Select a currency')}
            </span>
          )}
          <ChevronDown className="h-4 w-4" />
        </button>
        <div className="flex flex-row gap-2 items-center">
          {token && (
            <AddToWalletButton
              variant="text"
              p="0"
              height="auto"
              width="fit-content"
              tokenAddress={tokenAddress}
              tokenSymbol={token.symbol}
              tokenDecimals={token.decimals}
              tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
            />
          )}
          <button
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onMax?.()
              setCurrentClickedPercent('MAX')
            }}
            type="button"
            className="text-sm text-orange-200 opacity-60"
          >
            {t('Max')}
          </button>
        </div>
      </div>
    </div>
  )
}
